<template>
    <section class="mx-2 mt-3 border" style="border-radius:12px 12px 0px 0px">
        <div class="row mx-0 align-items-center border-bottom px-3 py-2">
            <div class="bg-general3 rounded-circle d-middle-center cr-pointer" style="width:20px;height:20px;" @click="redireccionar">
                <i class="icon-left text-white f-18" />
            </div>
            <div class="col-auto text-general f-18 f-600">
                Productos Importados
            </div>
            <div class="col-auto ml-auto px-1">
                <div class="bg-light-f5 br-8 d-middle-center shadow" style="height:32px;width:32px;">
                    <a :href="importacion.archivo" download="Stock_importado.xlsx">
                        <i class="icon-descargar-inventario text-general cr-pointer f-20" />
                    </a>
                </div>
            </div>

            <div class="col-auto">
                <div class="bg-general3 text-white f-14 cr-pointer px-4 br-8 d-middle-center" style="height:32px;" @click="modalanularProceso">
                    Anular
                </div>
            </div>
        </div>
        <div class="row mx-0 py-2 px-1 bg-light-f5">
            <div class="col d-flex">
                <i class="icon-ok-circled f-18 text-success" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-600">{{ importacion.productos_numero }}</p>
                    <p class="f-14">
                        <i>Productos importados</i>
                    </p>
                </div>
            </div>
            <div class="col d-flex">
                <i class="icon-comision text-general f-18" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-600">{{ convertMoney(importacion.productos_valor,cedis.idm_moneda) }}</p>
                    <p class="f-14">
                        <i>Inventario importado</i>
                    </p>
                </div>
            </div>
            <div class="col d-flex">
                <i class="icon-entrega-dinero text-general f-18" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-600">{{ convertMoney(importacion.valor_restante,cedis.idm_moneda) }}</p>
                    <p class="f-14">
                        <i>Inventario restante</i>
                    </p>
                </div>
            </div>
            <div class="col d-flex">
                <i class="icon-account-check f-20 text-general" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-600">{{ importacion.responsable }}</p>
                    <p class="f-14">
                        <i>Responsable</i>
                    </p>
                </div>
            </div>
            <div class="col d-flex">
                <i class="icon-fecha-fin f-20 text-general" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-600">{{ importacion.created_at | helper-fecha('DD MMM Y') }}</p>
                    <p class="f-14">
                        <i>Fecha de actualización</i>
                    </p>
                </div>
            </div>
        </div>

        <div class="row mx-0">
            <div class="col-12">
                <tabla-general
                :mostrar-buscador="false"
                :data="dataTable"
                class-header="text-general f-16"
                alto="calc(100vh - 381px)"
                >
                    <template slot="adicionales-izquierda">
                        <el-table-column :label="importacion.origen == 1 ? 'Barcode' : 'SKU'" min-width="120">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ importacion.origen == 1 ? row.barcode : row.sku }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Producto" width="200">
                            <template slot-scope="{row}">
                                <el-tooltip v-if="row.id_producto === null" placement="bottom" content="No se encontró el producto" effect="light">
                                    <i class="icon-attention-alt text-general-red f-20" />
                                </el-tooltip>
                                <p v-else class="text-general f-14">
                                    {{ row.nombre_producto }} - {{ row.presentacion }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor Compra" min-width="150" align="center">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ convertMoney(row.valor,cedis.idm_moneda) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Unidades Importadas" min-width="140" align="center">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ redondearNumero(row.cantidad) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor Total" min-width="150" align="center">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ convertMoney(row.valor_total,cedis.idm_moneda) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Unidades Restantes" min-width="140" align="center">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ redondearNumero(row.cantidad_restante) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor del Restante" min-width="150" align="center">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ convertMoney(row.valor_restante,cedis.idm_moneda) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Proveedor" width="180">
                            <template slot-scope="{row}">
                                <p class="text-general f-14">
                                    {{ row.proveedor || 'No registra' }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Observación" width="180" align="center">
                            <template slot-scope="{row}">
                                <el-tooltip placement="bottom" :content="row.comentario" effect="light">
                                    <i class="icon-card-text-outline text-general f-25" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <modal-anular-proceso ref="modalAnularProceso" @update="redireccionar" />
    </section>
</template>
<script>
import Productos from "~/services/productos/productos_stocks";
export default {
    beforeRouteEnter(to, from, next){
        next(vm => {
            // Obtenemos ruta anterior y parametros de la ruta
            vm.ruta_anterior_path = from.path;
            vm.ruta_anterior_name = from.name;
            if (vm.ruta_anterior_name === 'admin.cedis.ordenes-compra.detalle'){
                vm.id_oc = from.params.id_oc;
            }
        });
    },
    components: {
        modalAnularProceso: () => import('./partials/modalAnularRegistro')
    },
    data(){
        return {
            dataTable:[],
            importacion:{},
            id_oc: '',
            ruta_anterior_path: '',
            ruta_anterior_name: '',
        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        id_importacion(){
            return Number(this.$route.params.id_importacion)
        },
        cedis(){
            if(this.cedis_calculo.length == 0){
                return {}
            }
            return this.cedis_calculo.find(o=>o.id == this.id_cedis)
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        async listar(){
            try {
                const {data} = await Productos.getDetalleImportacion(this.id_importacion)
                this.importacion = data.importacion
                this.dataTable = data.registros

            } catch (e){
                this.error_catch(e)
            }
        },
        modalanularProceso(){
            this.$refs.modalAnularProceso.toggle(this.id_importacion);
        },
        redireccionar(){
            if (this.ruta_anterior_name === 'admin.cedis.ordenes-compra.detalle'){
                return this.$router.push({name: 'admin.cedis.ordenes-compra.detalle', params: {id_oc: this.id_oc, id_cedis: this.id_cedis}});
            }
            this.$router.push({name:'admin.cedis.historial-importaciones'});
        }
    }
}
</script>
